export const routers = {
  home: '/',
  post_detail: postId => `/p/${postId}`,
  article: '/p/tin-tuc',
  video: '/videos',
  short_videos: '/short-videos',
  short_video_detail: (short_video_id) =>  `/short_videos/${short_video_id}`,
  products: '/cho-2nong',
  modules: {
    weather: '/modules/thoi-tiet',
    npk: '/modules/npk',
    market_price: '/modules/gia-ca-thi-truong',
  },
  shop: shop_id => `/nguoi-dung/${shop_id}`,
  manager: {
    comment: '/nguoi-dung/quan-ly-binh-luan',
  },
  notice_schedule: (schedule_id) =>  `/nguoi-dung/thiet-lap-thong-bao/chinh-sua/${schedule_id}`,
  comment: {
    post: (post_id) => `/p/${post_id}`,
    video: (video_id) => `/videos/${video_id}`,
    article: (article_id) => `/tin-tuc/${article_id}`,
    product: (product_id) => `/san-pham/${product_id}`,
    technical_process: (technical_process_id) => `/quy-trinh-ky-thuat/danh-muc/${technical_process_id}`,
    image: (post_id, image_id) => `/p/${post_id}/i/${image_id}`,
  },
  subComment: {
    post: (post_id) => `/p/${post_id}`,
    video: (video_id) => `/videos/${video_id}`,
    article: (article_id) => `/tin-tuc/${article_id}`,
    product: (product_id) => `/san-pham/${product_id}`,
    technical_process: (technical_process_id) => `/quy-trinh-ky-thuat/danh-muc/${technical_process_id}`,
    image: (post_id, image_id) => `/p/${post_id}/i/${image_id}`,
  },
  invoice: {
    sales_order: (invoice_id) => `/nguoi-dung/thong-tin-don-hang-ban/${invoice_id}`,
  },
  business_association: (invoice_id, business_id) => `/lien-ket-danh-nghiep/thong-tin-don-hang/${invoice_id}/business/${business_id}`,
  market_place: (market_price_id) => `/modules/thong-tin-gia-ca-thi-truong/${market_price_id}`,
  system: {
    article: '/p/tin-tuc',
    post: '/',
    weather: '/modules/thoi-tiet',
    npk: '/modules/npk',
    market_price: '/modules/gia-ca-thi-truong',
    technical_process: '/quy-trinh-ky-thuat',
    video: '/videos',
    traceability: '/modules/truy-xuat-nguon-goc',
    business_association: '',
    handbook_of_pest: '/diagnostics',
    shop_gitf: '/shop-qua-tang',
    short_video: '/videos',
  },
  default: '',
};
