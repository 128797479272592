import React, { useEffect, useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { DOMAIN } from "constants";
import AppContext from "components/app_context";
import { validURL } from "lib";
import LeftSidebar from "components/left_sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import * as authActions from "actions/auth";
import * as locationActions from "actions/location";
import AddMetaTags from "components/add_meta_tags";
import { getProfile } from "apis/auth";
import { toastError } from "helpers/toastHelper";
import { setParamsTrackActivity } from "lib";
import { createTrackActivity } from "actions/track_activity";
import SelectOptions from "components/Select";
import _ from "lodash";

function Profile(props) {
  const { current_user, setUser } = useContext(AppContext);
  const tagInput = useRef();
  const { authActionsCreator } = props;
  const { locationActionsCreator } = props;
  const { updateUser } = authActionsCreator;
  const { fetchProvince, fetchDistrict } = locationActionsCreator;
  const [birthdate, setBirthdate] = useState(null);
  const [name, setName] = useState("");
  const [tags, setTags] = useState([]);
  const [address, setAddress] = useState("");
  const [acreage, setAcreage] = useState("");
  const [gender, setGender] = useState("");
  const [district, setDistrict] = useState("");
  const [province, setProvince] = useState("");
  const [avatar, setAvatar] = useState("");
  const [url_avatar, setUrlAvatar] = useState(null);
  const [dataUser, setDataUser] = useState(null);
  const { data: provinces_data } = props.listProvinces;
  const { data: districts_data } = props.listDistricts;
  const dispatch = useDispatch();

  useEffect(() => {
    setUser(props.user_data.current_user);
    getProfile(props.user_data.current_user?.id).then((response) => {
      setDataUser(response.data.data);
    });
  }, [props.user_data.current_user]);

  useEffect(() => {
    fetchProvince();
    fetchDistrict({ province_id: province });
  }, [fetchProvince, fetchDistrict, province]);

  const initializeFields = () => {
    setTags(dataUser["family_tree"]);
    setBirthdate(new Date(dataUser["birthdate"]));
    setName(dataUser["name"]);
    setAddress(dataUser["address"]);
    setAcreage(dataUser["acreage"]);
    setGender(dataUser["gender"]);
    setDistrict(dataUser["district_id"]);
    setProvince(dataUser["province_id"]);
  };

  useEffect(() => {
    if (dataUser) {
      initializeFields();
    }
  }, [dataUser]);
  const uploadAvatar = (e) => {
    let file = e.target.files[0];
    let url = URL.createObjectURL(file);
    setAvatar(file);
    setUrlAvatar(url);
  };

  const submitForm = (e) => {
    if (parseInt(acreage) < 0) {
      return toastError("Diện tích tối thiểu là 0");
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("gender", gender);
    formData.append("birthdate", birthdate);
    formData.append("address", address);
    formData.append("acreage", acreage);
    formData.append("image", avatar);
    formData.append("province_id", province);
    formData.append("district_id", district);
    formData.append("family_tree", JSON.stringify(tags));
    dispatch(createTrackActivity(setParamsTrackActivity("Update profile user", "PUT", {}, "user_update")));
    updateUser({ formData: formData });
  };



  const onChangeTags = (e) => {
    if (tags.includes(e.target.value)) return (e.target.value = "");

    let tag = e.target.value.trim().toLowerCase();
    if (tag) {
      setTags((tags) => [...tags, tag]);
    }
    tagInput.current.value = null;
  };

  const removeTag = (value) => {
    setTags(tags.filter((tag) => tag != value));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onChangeTags(e);
    }
  };

  let handleDate = (date) => {
    setBirthdate(null);
    setBirthdate(new Date(date));
  };

  const linkImage = () => {
    if (dataUser?.image === null) {
      return "/images/avatar.png";
    }
    if (validURL(dataUser?.image)) {
      return dataUser?.image;
    } else if (!validURL(dataUser?.image)) {
      return DOMAIN + dataUser?.image;
    }
  };

  const listProvincesFormatted = _.map(provinces_data, (item) => {
      return {
        value: item.id,
        label: item.name,
      }
  })

  const listDistrictFormatted = _.map(districts_data, (item) => {
    return {
      value: item.id,
      label: item.name,
    }
})

  return (
    <div className="profile">
      <AddMetaTags title={"Trang cá nhân"} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <div className="container profile-page">
            {dataUser && (
              <>
                <div className="text-center inf-box">
                  <div className="upload-avatar">
                    {url_avatar ? (
                      <img src={url_avatar} alt="avatar" className="avatar" />
                    ) : (
                      <img src={linkImage()} alt="avatar" className="avatar" />
                    )}
                    <span className="icon-camera" />
                    <input type="file" className="form-control" onChange={uploadAvatar} defaultValue="" />
                  </div>
                  <div className="">
                    <p className="name-txt fw-bold">{dataUser["name"]}</p>
                    <Link to={`/nguoi-dung/cap-nhat-mat-khau`} className="change-pwd fs-6 ms-0">
                      [Đổi mật khẩu]
                    </Link>
                  </div>
                  <div className="user-type mt-2">
                    <span className="fst-italic text-muted">{dataUser?.role_type === "normal" ? "Nông dân thường" : "Chuyên gia"}</span>
                  </div>
                  <div className="row">
                    <div className="col-6 text-end">
                      <div className="level-box text-center d-inline-block">
                        <div className="level text-center">{dataUser?.user_level === null ? "Chưa có hạng" : dataUser?.user_level}</div>
                        <div className="txt text-center">Xếp hạng</div>
                      </div>
                    </div>
                    <div className="col-6 text-start">
                      <div className="bonus-box text-center d-inline-block">
                        <div className="point text-center">{dataUser?.points}</div>
                        <div className="txt text-center">Điểm hiện có</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile-user">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <label>Họ và tên</label>
                      <input className="form-control" type="text" defaultValue={dataUser.name} onChange={(e) => setName(e.target.value)} />
                      <label className="alert-invalid d-none">Invalid</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <label>Ngày sinh</label>
                      <DatePicker
                        className="form-control"
                        placeholderText={"dd/mm/yyyy"}
                        selected={birthdate}
                        onChange={(date) => handleDate(date)}
                        dateFormat="dd/MM/yyyy"
                      />
                      <label className="alert-invalid d-none">Invalid</label>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <label>Giới tính</label>
                      <div className=" gender">
                        <select
                          className="form-select selectpicker"
                          aria-label="Default select example"
                          onChange={(e) => setGender(e.target.value)}
                          defaultValue={dataUser.gender}
                        >
                          <option value="select">Chọn</option>
                          <option value="male">Nam</option>
                          <option value="female">Nữ</option>
                          <option value="other">Khác</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <label>Số điện thoại</label>
                      <input className="form-control" type="text" defaultValue={current_user["phone"]} disabled readOnly />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <label>Loại cây trồng</label>
                      <div className="bs-example">
                        <div className="bootstrap-tagsinput">
                          {tags.map(function (tag, index) {
                            return (
                              <span key={index} className="tag label label-info">
                                {tag}
                                <span data-role="remove" className="remove" onClick={() => removeTag(tag)}></span>
                              </span>
                            );
                          })}
                          <input type="text" placeholder="" onBlur={onChangeTags} onKeyDown={handleKeyDown} ref={tagInput} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <label>
                        Diện tích canh tác (m<sup>2</sup>)
                      </label>
                      <input
                        min={0}
                        className="form-control"
                        type="number"
                        defaultValue={dataUser.acreage}
                        onChange={(e) => setAcreage(e.target.value)}
                        onPaste={(e) => e.preventDefault()}
                      />
                      <label className="alert-invalid d-none">Invalid</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <label>Địa chỉ</label>
                      <input
                        className="form-control mb-2"
                        type="text"
                        defaultValue={dataUser.address !== "null" ? dataUser.address : ""}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      <label className="alert-invalid d-none">Invalid</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="">
                        {/* <select
                          className="form-select selectpicker"
                          onChange={(e) => setProvince(e.target.value)}
                          value={province}
                        >
                          {provinces_data.map(function (province, index) {
                            return (
                              <option key={province.id} value={province.id}>
                                {province.name}
                              </option>
                            );
                          })}
                        </select> */}
                        <SelectOptions  setValue={setProvince} data={listProvincesFormatted} defaultValue={province} placeholder="Chọn tỉnh thành"/>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="">
                        <SelectOptions  setValue={setDistrict} data={listDistrictFormatted} defaultValue={district} placeholder="Chọn quận huyện"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button type="button" className="btn btn-danger" onClick={submitForm}>
                    cập nhật{" "}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user_data: state.login.user_data,
    listProvinces: state.location.listProvinces,
    listDistricts: state.location.listDistricts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authActionsCreator: bindActionCreators(authActions, dispatch),
  locationActionsCreator: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
