import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import productReducer from './product';
import authReducer from './auth';
import postReducer from './post';
import articleReducer from './article';
import postCatalogueReducer from './post_catalogue';
import locationReducer from './location';
import notificationReducer from './notification';
import fertilizerReducer from './fertilizer';
import diagnosticReducer from './diagnostic';
import weatherReducer from './weather';
import marketReducer from './gia_ca_thi_truong';
import expertReducer from './expert';
import sitemapReducer from './sitemap';
import managerReducer from './manager';
import postDetailReducer from './postDetailReducer';
import traceabilityReducer from './traceability';
import TechnicalProcessReducer from './technical_process';
import GiftStore from './gift';
import Albums from './albums';
import cartReducer from './cart';
import invoiceReducer from './invoice';
import homeReducer from './home';
import businessReducer from './business';
import ttsReducer from './tts';
import bannerReducer from './banner';

const rootReducer = history =>
  combineReducers({
    user: authReducer,
    login: authReducer,
    product: productReducer,
    post: postReducer,
    article: articleReducer,
    post_catalogue: postCatalogueReducer,
    location: locationReducer,
    notification: notificationReducer,
    router: connectRouter(history),
    fertilizer: fertilizerReducer,
    diagnostic: diagnosticReducer,
    weather: weatherReducer,
    market: marketReducer,
    expect: expertReducer,
    sitemap: sitemapReducer,
    manager: managerReducer,
    traceability: traceabilityReducer,
    postDetail: postDetailReducer,
    technicalProcess: TechnicalProcessReducer,
    gift: GiftStore,
    albums: Albums,
    carts: cartReducer,
    invoices: invoiceReducer,
    home: homeReducer,
    business: businessReducer,
    tts: ttsReducer,
    banner: bannerReducer,
  });

export default rootReducer;
