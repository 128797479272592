import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as postActions from 'actions/post';
import * as authActions from 'actions/auth';
import * as managerActions from 'actions/manager';
import { PAGE } from 'constants';
import Post from 'components/shared/post/index';
import AppContext from 'components/app_context';
import LeftSidebar from 'components/left_sidebar';
import RightSidebar from 'components/right_sidebar';
import AddPost from 'components/add_post';
import Loader from 'components/loader';
import FadeIn from 'react-fade-in';
import PostsPlaceholder from 'components/placeholder/posts';
import AddMetaTags from 'components/add_meta_tags';
import { managerDeleteComment, managerDeleteSubComment } from 'apis/manager';
import _ from 'lodash';
import Albums from '../../components/Albums';
import { fetchFollowing, followUser, unFollow, fetchPointOfUser, updatePointOfUser } from 'actions/auth';
import ModalSharePost from 'components/shared/ModalSharePost';
import { toastError } from 'helpers/toastHelper';
import { setParamsTrackActivity } from 'lib';
import { createTrackActivity } from 'actions/track_activity';

function Forums(props) {
  const dispatch = useDispatch();
  const abortController = new AbortController();
  const postRef = useRef();
  const { data: listFollow } = useSelector(state => state.login.list_following);
  const point = useSelector(state => state.login.pointOfUser.point);
  const { keyword, setDataFilter, current_user, hashtag, postIdForScroll } = useContext(AppContext);
  const [page, setPage] = useState(PAGE);
  const [pageMore, setPageMore] = useState(1);
  const [postId, setPostId] = useState(null);
  const [currentPost, setCurrentPost] = useState(null);
  const [content, setContent] = useState('');
  const { postActionsCreator, authActionsCreator, managerActionsCreator } = props;
  const {
    fetchPost,
    fetchLoadMorePost,
    likePost,
    unlikePost,
    addPost,
    deletePost,
    createCommentPost,
    deleteCommentPost,
    sharePost,
    reportPost,
    fetchCommentPost,
    fetchAnswersComments,
    replyCommentPost,
    reportComment,
    likeComment,
    unlikeComment,
    updateComment,
    updateAnswerComment,
    fetchLoadMoreComments,
    modifyDataDelete,
  } = postActionsCreator;
  const { sharePoint, followPost, unFollowPost } = authActionsCreator;
  const { data: dataUpdateComment } = props.updateComment;
  const { data: dataUpdateAnswerComment } = props.managerUpdateAnswerComment;
  const { data: listAnsComments } = props.listCommentAnswers;
  const comments = props.listCommentPosts;
  const { data: dataCreateCommet } = props.dataCreateComment;
  const { managerUpdateComment, managerUpdateAnswerComment, managerDeletePost } = managerActionsCreator;
  const { data: litsPost, isLoading, isLoadMore, meta } = props.listPosts;
  const [tab, setTab] = useState('created_at');
  const posts = _.orderBy(litsPost, ['pin_top', tab], ['asc', 'desc']);
  const [sharedPoint, setSharedPoint] = useState(0);

  const actionLikeComment = (id, type, isLiked) => {
    if (isLiked) {
      unlikeComment({ classable_id: id, classable_type: type });
    } else {
      likeComment({ classable_id: id, classable_type: type });
    }
  };

  const actionFetchCommentPost = id => {
    fetchCommentPost({
      classable_id: id,
      classable_type: 'Post',
      page: 1,
      sort: 'total_likes',
    });
  };

  const postFilter = value => {
    setDataFilter(value);
  };

  const listener = e => {
    setPage(page + 1);
    fetchLoadMorePost({ page: page + 1, limit: 25, container: 'home' });
  };

  const actionLoadMoreCommentsPost = postId => {
    setPageMore(pageMore + 1);
    fetchLoadMoreComments({
      page: 1,
      classable_id: postId,
      classable_type: 'Post',
    });
  };

  const actionLike = async (id, type, isLiked) => {
    if (isLiked) {
      unlikePost({ classable_id: id, classable_type: type });
    } else {
      await likePost({ classable_id: id, classable_type: type });
      dispatch(createTrackActivity(setParamsTrackActivity('Like post ', 'GET', {}, 'like_posts')));
    }
  };

  const addComment = async (id, type, content) => {
    const params = {
      commentable_id: id,
      commentable_type: type,
      content: content,
    };
    await createCommentPost(params);
    dispatch(createTrackActivity(setParamsTrackActivity('comment_post', 'POST', params, 'comments_post')));
  };

  const deleteComment = (id, role, type_comment) => {
    if (type_comment === 'Comment') {
      if (role === 'admin' || role === 'smod') {
        managerDeleteComment(id).then(res => {
          fetchPost({
            keyword: keyword || hashtag,
            page: page,
            limit: 25,
          });
        });
      } else {
        deleteCommentPost({ id: id });
        fetchPost({ keyword: keyword || hashtag, page: page, limit: 25 });
      }
    } else {
      managerDeleteSubComment(id).then(res => {
        fetchPost({ keyword: keyword || hashtag, page: page, limit: 25 });
      });
    }
  };

  const actionDelete = id => {
    if (current_user?.manager_type == 'admin') {
      managerDeletePost({ id: id });
    } else {
      deletePost({ id: id });
    }
  };

  const actionSharePost = (id, type, content) => {
    sharePost({
      classable_id: id,
      classable_type: type,
      share_type: 'config_share',
      post_type: 'public',
      description: content,
    });
  };

  function actionSharePoint(access_token, receiver_id, points) {
    sharePoint({
      access_token: access_token,
      receiver_id: receiver_id,
      points: points,
    });
    // dispatch(updatePointOfUser(point - points));
  }

  const actionReportPost = (id, reason) => {
    reportPost({ id: id, reason: reason });
  };

  const actionReportComment = (user_id, reason, id, type) => {
    reportComment({
      id: user_id,
      reason: reason,
      classable_id: id,
      classable_type: type,
    });
  };
  const debounceDropDown = useCallback(
    _.debounce(keyword => fetchPost({ hashtag, page: page, limit: 25 }), 500),
    [],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (hashtag) {
        debounceDropDown(hashtag);
      } else {
        await fetchPost({
          keyword: keyword || hashtag,
          page: page,
          limit: 25,
        });
      }

      setTimeout(() => {
        const postElement = document.getElementById(postIdForScroll);
        if (postElement) {
          postElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 3000);
    };

    fetchData();
    return () => {
      abortController.abort();
    };
  }, [fetchPost, keyword, hashtag, tab]);

  const fetchProfilePoint = async () => {
    if (current_user) {
      dispatch(createTrackActivity(setParamsTrackActivity('forums', 'GET', {}, 'forums')));
      dispatch(fetchPointOfUser(current_user.id));
    }
  };

  //call track activity
  useEffect(() => {
    fetchProfilePoint();
  }, [current_user]);

  const actionReplyComment = (id, content, user_id) => {
    replyCommentPost({ id: id, content: content, replier_id: user_id });
  };

  const actionUpdateComment = (type, id, content) => {
    if (type == 'Comment') {
      if (current_user?.manager_type == 'admin' || current_user.manager_type == 'smod') {
        managerUpdateComment({ id: id, content: content });
      } else {
        updateComment({ id: id, content: content });
      }
    } else {
      if (
        current_user?.manager_type == 'admin' ||
        current_user.manager_type == 'smod' ||
        current_user.manager_type == 'mod'
      ) {
        managerUpdateAnswerComment({ id: id, content: content });
      } else {
        updateAnswerComment({ id: id, content: content });
      }
    }
  };

  const actionSortComment = (id, type) => {
    if (type === 'asc') {
      fetchCommentPost({
        classable_id: id,
        classable_type: 'Post',
        page: 1,
        limit: 5,
        sort: 'total_likes',
      });
    } else {
      fetchCommentPost({
        classable_id: id,
        classable_type: 'Post',
        page: 1,
        limit: 5,
        sort: 'created_at',
      });
    }
  };
  const handleFollowPost = (classable_type, classable_id) => {
    followPost({ classable_id: classable_id, classable_type: classable_type });
  };

  const handleUnFollowPost = (classable_type, classable_id) => {
    unFollowPost({
      classable_id: classable_id,
      classable_type: classable_type,
    });
  };
  const handleFollowUser = async shop_id => {
    await dispatch(
      followUser({
        classable_id: shop_id,
        classable_type: 'Shop',
        access_token: current_user?.token_user,
      }),
    );
    dispatch(fetchFollowing({ page: '', limit: '' }));
  };

  const handleUnFollowUser = async shop_id => {
    dispatch(
      unFollow({
        classable_type: 'Shop',
        classable_id: shop_id,
        access_token: current_user?.token_user,
      }),
    );
  };

  const handleSharePost = async () => {
    if (current_user) {
      await dispatch(
        sharePost({
          classable_id: currentPost.id,
          classable_type: 'Post',
          share_type: 'config_share',
          post_type: 'public',
          description: content,
        }),
      );
      setCurrentPost(null);
      setContent('');
    } else {
      toastError('Có lỗi xảy ra, vui lòng đăng nhập lại');
    }
  };

  return (
    <div className="home">
      <AddMetaTags />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-6 col-sm-12">
          <AddPost current_user={current_user} addPost={addPost} />
          <Albums />
          {isLoading && (
            <FadeIn>
              <PostsPlaceholder />
            </FadeIn>
          )}
          <section className="news-sectn">
            <nav className="d-flex align-items-center mb-3 post">
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a
                  className="nav-item nav-link animated-btn active pe-auto"
                  id="hotnews-tab"
                  data-toggle="tab"
                  href="#hotnews"
                  role="tab"
                  aria-controls="hotnews"
                  aria-selected="true"
                  onClick={() => setTab('created_at')}
                >
                  <span>Mới nhất</span>
                </a>
                {current_user && (
                  <a
                    className="nav-item nav-link animated-btn new-btn"
                    id="newnews-tab"
                    data-toggle="tab"
                    href="#newnews"
                    role="tab"
                    aria-controls="newnews"
                    aria-selected="false"
                    onClick={() => setTab('total_like')}
                  >
                    <span>Yêu thích</span>
                  </a>
                )}
              </div>
            </nav>
          </section>
          <FadeIn>
            <div ref={postRef}>
              {_.orderBy(posts, ['pin_top'], ['desc'])?.map(function (post, index) {
                const isOwner = current_user ? post.user_id === current_user.id : false;
                return (
                  <div key={post.id} id={post.id}>
                    <Post
                      listFollow={listFollow}
                      post={post}
                      postFilter={postFilter}
                      actionLike={actionLike}
                      isOwner={isOwner}
                      actionDelete={actionDelete}
                      deleteComment={deleteComment}
                      addComment={addComment}
                      current_user={current_user}
                      actionLikeComment={actionLikeComment}
                      actionReportComment={actionReportComment}
                      actionSharePost={handleSharePost}
                      actionReportPost={actionReportPost}
                      actionSharePoint={actionSharePoint}
                      actionFetchCommentPost={actionFetchCommentPost}
                      comments={comments.data}
                      fetchAnswersComments={fetchAnswersComments}
                      listAnsComments={listAnsComments}
                      actionReplyComment={actionReplyComment}
                      actionUpdateComment={actionUpdateComment}
                      dataUpdateComment={dataUpdateComment}
                      dataUpdateAnswerComment={dataUpdateAnswerComment}
                      isLoadMore={comments.isLoadMore}
                      actionLoadMoreCommentsPost={actionLoadMoreCommentsPost}
                      actionSortComment={actionSortComment}
                      replyCommentPost={replyCommentPost}
                      dataCreateCommet={dataCreateCommet}
                      setPostId={setPostId}
                      postId={postId}
                      setPageMore={setPageMore}
                      handleFollowPost={handleFollowPost}
                      handleUnFollowPost={handleUnFollowPost}
                      handleFollowUser={handleFollowUser}
                      handleUnFollowUser={handleUnFollowUser}
                      setCurrentPost={setCurrentPost}
                      modifyDataDelete={modifyDataDelete}
                      meta={meta}
                      sharedPoint={sharedPoint}
                      point={point}
                    />
                  </div>
                );
              })}
            </div>
          </FadeIn>
          <ModalSharePost
            post={currentPost}
            handleClose={() => setCurrentPost(null)}
            setContent={setContent}
            onSubmit={handleSharePost}
            current_user={current_user}
            content={content}
          />
          <Loader isLoading={isLoading} />
          {isLoadMore && litsPost?.length > 24 && (
            <div className="post-more  background-clr-f5 hover" onClick={listener}>
              <span className="txt fw-500">
                {' '}
                <u>Xem thêm</u>
              </span>
              <span className="icon-arrow-solid"></span>
            </div>
          )}
        </div>
        <RightSidebar />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    listPosts: state.post.listPosts,
    listCommentPosts: state.post.listCommentsPost,
    listCommentAnswers: state.post.listCommentsAnswer,
    updateComment: state.post.updateComment,
    managerUpdateComment: state.manager.dataUpdateComment,
    managerUpdateAnswerComment: state.manager.dataUpdateAnswerComment,
    dataCreateComment: state.post.createComment,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    postActionsCreator: bindActionCreators(postActions, dispatch),
    authActionsCreator: bindActionCreators(authActions, dispatch),
    managerActionsCreator: bindActionCreators(managerActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Forums);
