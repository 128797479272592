import React from 'react';
import Select from 'react-select'

const SelectOptions  = ({data, setValue, defaultValue, placeholder}) => {
  const customStyles = {
    control: base => ({
      ...base,
    }),
  };

  return (
    <>
      <Select
        options={data}
        className="basic-single"
        classNamePrefix="select"
        placeholder={placeholder}
        hideSelectedOptions={true}
        noOptionsMessage = {() => "Không tìm thấy"}
        onChange={(e) => setValue(e?.value)}
        isClearable={true}
        styles={customStyles}
        value={ defaultValue && data.filter(obj => obj.value === defaultValue)}
       />
    </>
  );
}

export default SelectOptions;
