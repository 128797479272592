import { all, call, put } from 'redux-saga/effects';
import { toastError, toastSuccess } from 'helpers/toastHelper';
import {
  loadMoreCommentFailed,
  loadMoreCommentSuccess,
  fetchProductSuccess,
  fetchProductFailed,
  fetchProductID,
  fetchProductIDSuccess,
  fetchProductIDFailed,
  fetchLoadMoreProductSuccess,
  fetchLoadMoreProductFailed,
  fetchHighlightProductSuccess,
  fetchHighlightProductFailed,
  fetchUserProductSuccess,
  fetchUserProductFailed,
  fetchRelationProduct,
  fetchRelationProductSuccess,
  fetchRelationProductFailed,
  fetchCatalogueOfProductSuccess,
  fetchCatalogueOfProductFailed,
  fetchCommentProduct,
  fetchCommentProductSuccess,
  fetchCommentProductFailed,
  fetchFavoritesProductSuccess,
  fetchFavoritesProductFailed,
  createFavoriteProductSuccess,
  createFavoriteProductFailed,
  fetchProductsByUserSuccess,
  fetchProductsByUserFailed,
  fetchSidebarProductSuccess,
  fetchSidebarProductFailed,
  likeCommentProductSuccess,
  likeCommentProductFailed,
  unLikeCommentProductFailed,
  unLikeCommentProductSuccess,
  reportCommentProductSuccess,
  reportCommentProductFailed,
  replyCommentProductSuccess,
  replyCommentProductFailed,
  updateChildCommentFailed,
  updateChildCommentSuccess,
  updateCommentFailed,
  updateCommentSuccess,
  destroyChildCommentSuccess,
  destroyCommentSuccess,
  managerDeleteCommentSuccess,
  managerDeleteSubCommentSuccess,
  managerUpdateChildCommentSuccess,
  managerUpdateCommentSuccess,
  fetchParentCatalogueSuccess,
  fetchAnswerCommentSuccess,
  fetchAnswerCommentFailed,
} from 'actions/product';

import {
  getProducts,
  getProductId,
  getHighlightProducts,
  getUserProducts,
  getFavoritesProducts,
  favoriteProduct,
  deleteFavoriteProduct,
  getProductsByUser,
  likeCommentProduct,
  unLikeCommentProduct,
} from 'apis/product';
import { getCataloguesProducts, getCataloguesProductsDetail } from 'apis/catalogue';
import {
  createComment,
  getComment,
  reportComment,
  replyComment,
  updateComment,
  updateCommentAnswer,
  destroyChildComment,
  destroyComment,
  getAnswersComment,
} from 'apis/comment';
import { STATUS_CODE } from 'constants/index';
import {
  managerDeleteComment,
  managerDeleteSubComment,
  managerUpdateAnswerComment,
  managerUpdateComment,
} from 'apis/manager';
import { fetchChildCatalogueSuccess } from '../../redux/actions/product';
import { createProductByOwner, deleteProduct, updateProductByOwner } from '../../apis/product';

export function* managerUpdateCommentProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(managerUpdateComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerUpdateCommentSuccess(data));
    }
  } catch (error) {}
}
export function* managerUpdateChildComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(managerUpdateAnswerComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerUpdateChildCommentSuccess(data));
    }
  } catch (error) {}
}
export function* fetchLoadMoreComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(loadMoreCommentSuccess(data));
    }
  } catch (error) {}
}

export function* managerClickDeleteComment({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(managerDeleteComment, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerDeleteCommentSuccess(id));
    }
  } catch (error) {}
}
export function* managerClickDeleteSubComment({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(managerDeleteSubComment, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerDeleteSubCommentSuccess(id));
    }
  } catch (error) {}
}

export function* userDestroyComment({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(destroyComment, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(destroyCommentSuccess(id));
    }
  } catch (error) {}
}
export function* userDestroyChildComment({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(destroyChildComment, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(destroyChildCommentSuccess(id));
    }
  } catch (error) {}
}
export function* userUpdateCommentProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(updateComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(updateCommentSuccess(data));
    }
  } catch (error) {}
}
export function* userUpdateChildComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(updateCommentAnswer, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(updateChildCommentSuccess(data));
    }
  } catch (error) {}
}

export function* userReplyCommentProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(replyComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(replyCommentProductSuccess(data));
    } else {
      yield put(replyCommentProductFailed(data));
    }
  } catch (error) {}
}

export function* userReportCommentProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(reportComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      toastSuccess('Báo cáo bình luận thành công');
      yield put(reportCommentProductSuccess(data));
    } else {
      yield put(reportCommentProductFailed(data));
    }
  } catch (error) {}
}

export function* userLikeCommentProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(likeCommentProduct, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(likeCommentProductSuccess(data));
    } else {
      yield put(likeCommentProductFailed(data));
    }
  } catch (error) {}
}

export function* userUnLikeCommentProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(unLikeCommentProduct, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(unLikeCommentProductSuccess(params));
    } else {
      yield put(unLikeCommentProductFailed(data));
    }
  } catch (error) {}
}

export function* fetchProducts({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getProducts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchProductSuccess(data));
    } else {
      yield put(fetchProductFailed(data));
    }
  } catch (error) {}
}

export function* fetchRelationProducts({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getProducts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchRelationProductSuccess(data));
    } else {
      yield put(fetchRelationProductFailed(data));
    }
  } catch (error) {}
}

export function* fetchProductDetail({ payload }) {
  try {
    const { id } = payload;
    const resp = yield call(getProductId, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchProductIDSuccess(data));
      yield put(
        fetchRelationProduct({
          page: 1,
          limit: 8,
          product_id: data.data.id,
        }),
      );
      yield put(
        fetchCommentProduct({
          classable_type: 'Product',
          classable_id: data.data.id,
          page: 1,
          limit: 5,
          sort: 'total_likes',
        }),
      );
    } else {
      yield put(fetchProductIDFailed(data));
    }
  } catch (error) {}
}

export function* fetchLoadMoreProducts({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getProducts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchLoadMoreProductSuccess(data));
    } else {
      yield put(fetchLoadMoreProductFailed(data));
    }
  } catch (error) {}
}

export function* fetchHighlightProducts({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getHighlightProducts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchHighlightProductSuccess(data));
    } else {
      yield put(fetchHighlightProductFailed(data));
    }
  } catch (error) {}
}

export function* fetchUserProducts({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getUserProducts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchUserProductSuccess(data));
    } else {
      yield put(fetchUserProductFailed(data));
    }
  } catch (error) {}
}

export function* fetchCatalogueOfProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getCataloguesProducts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchCatalogueOfProductSuccess(data));
    } else {
      yield put(fetchCatalogueOfProductFailed(data));
    }
  } catch (error) {}
}

export function* fetchChildCatalogueOfProductByParent({ payload }) {
  try {
    const arrayPromise = payload.map(id => getCataloguesProductsDetail(id));
    const response = yield all(arrayPromise);
    const listChildCatalogue = response.map(it => it.data.data);
    yield put(fetchChildCatalogueSuccess(listChildCatalogue));
  } catch (error) {}
}

export function* userFetchParentCatalogue({ payload }) {
  try {
    const arrayPromise = payload.parent_id.map(id => getCataloguesProductsDetail(id));
    const resp = yield all(arrayPromise);
    const listChildCatalogue = resp.map(it => it.data.data);
    yield put(fetchParentCatalogueSuccess(listChildCatalogue));
  } catch (error) {}
}

export function* fetchUserCommentProducts({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchCommentProductSuccess(data));
    } else {
      yield put(fetchCommentProductFailed(data));
    }
  } catch (error) {}
}

export function* userCreateCommentProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(createComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(
        fetchCommentProduct({
          classable_type: 'Product',
          classable_id: data['data'].commentable_id,
        }),
      );
    } else {
      yield put(fetchCommentProductFailed(data));
    }
  } catch (error) {}
}

export function* fetchUserFavoritesProducts({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getFavoritesProducts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchFavoritesProductSuccess(data));
    } else {
      yield put(fetchFavoritesProductFailed(data));
    }
  } catch (error) {}
}

export function* userCreateFavouriteProduct({ payload }) {
  try {
    const { params } = payload;
    const { classable_id } = params;
    const resp = yield call(favoriteProduct, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchProductID(classable_id));
    } else {
      yield put(createFavoriteProductFailed(data));
    }
  } catch (error) {}
}

export function* userDeleteFavouriteProduct({ payload }) {
  try {
    const { id, productId } = payload.params;
    const resp = yield call(deleteFavoriteProduct, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchProductID(productId));
    } else {
      yield put(createFavoriteProductFailed(data));
    }
  } catch (error) {}
}

export function* fetchProductsByUser({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getProductsByUser, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchProductsByUserSuccess(data));
    } else {
      yield put(fetchProductsByUserFailed(data));
    }
  } catch (error) {}
}
export function* fetchSidebarProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getProducts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchSidebarProductSuccess(data));
    }
  } catch (error) {
    yield put(fetchSidebarProductFailed(error));
  }
}

export function* createProduct({ payload }) {
  try {
    const { params, callback } = payload;
    const response = yield call(createProductByOwner, params);
    yield put({
      type: 'CREATE_PRODUCT_SUCCESS',
      payload: response.data.data,
    });
    callback();
  } catch (error) {}
}

export function* updateProduct({ payload }) {
  try {
    const { params, callback, id } = payload;
    const response = yield call(updateProductByOwner, params, id);
    yield put({
      type: 'UPDATE_PRODUCT_SUCCESS',
      payload: response.data.data,
    });
    callback();
  } catch (error) {}
}

export function* deleteProductByOwner({ payload }) {
  try {
    const { id } = payload;
    yield call(deleteProduct, id);
    yield put({
      type: 'DELETE_PRODUCT_SUCCESS',
      payload: id,
    });
  } catch (error) {}
}
export function* fetchAnsCommentProduct({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getAnswersComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchAnswerCommentSuccess(data));
    }
  } catch (error) {}
}
