import React from 'react';
import FadeIn from 'react-fade-in';
import { Link } from 'react-router-dom';
import NumberFormat from '../../../components/number_format';

const ProductOfEnterprise = ({ products }) => {
  return (
    <div className="mt-2">
      <h5 className="title-line-lt text-uppercase fw-bold my-3">Danh sách sản phẩm</h5>
      <FadeIn className="row prod-list" childClassName="col-md-3 col-sm-6 col-xs-12">
        {!!products.length ? (
          products.map(product => {
            return (
              <div key={product.id} className="item-lst hover">
                <Link to={`/san-pham/${product?.slug}`}>
                  <div
                    className="bg-img-orgnal"
                    style={{
                      background: `url(${product?.images[0]?.['name'] ?? 'images/photos/no_image.png'})`,
                    }}
                  />
                  <div className="prod-inf">
                    <div className="txt-name text-capitalize">{product.title}</div>
                    <div className="locatn">
                      <span className="icon-location01" />
                      <span className="txt-city">{product?.shop?.province_name || 'Không xác định'}</span>
                    </div>
                    <div className="price-item">
                      <div className="price01">
                        <span className="d-block text-muted">Giá lẻ:</span>
                        {product.retail_price > 0 ? (
                          <span className="price-clr">
                            &nbsp;<NumberFormat>{product.retail_price}</NumberFormat> đ/{product.unit_name}
                          </span>
                        ) : (
                          <span className="price-clr">Liên hệ</span>
                        )}
                      </div>
                      <div className="price02 mt-1">
                        <span className="d-block text-muted">Lượt xem: {product.view_count * 10}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        ) : (
          <div className="font-italic gray-clr ml-2">Không có sản phẩm</div>
        )}
      </FadeIn>
    </div>
  );
};

export default ProductOfEnterprise;
