import React from 'react';
import FadeIn from 'react-fade-in';
import { Link } from 'react-router-dom';
import NumberFormat from '../../../components/number_format';
import { imageUrl } from '../../../lib';

const HighlightProducts = ({ data, onLoadmoreProduct, productLimit }) => {
  return (
    <>
      <div className="mt-1">
        <h5 className="title-line-lt text-uppercase fw-bold my-3">Lựa chọn hôm nay</h5>
        <FadeIn className="row prod-list" childClassName="col-md-3 col-sm-6 col-xs-12">
          {data &&
            data.slice(0, productLimit).map(function (product) {
              return (
                <div key={product.id} className="item-lst hover">
                  <Link to={`/san-pham/${product?.slug}`}>
                    <div
                      className="bg-img-orgnal"
                      style={{
                        background: `url(${imageUrl(product?.images[0]?.['name']) ?? 'images/photos/no_image.png'})`,
                      }}
                    />
                    <div className="prod-inf">
                      <div className="txt-name text-capitalize">{product.title}</div>
                      {product.province_name ? (
                        <div className="locatn">
                          <span className="icon-location01" />
                          <span className="txt-city">{product?.shop?.province_name}</span>
                        </div>
                      ) : (
                        <div className="locatn">
                          <span className="icon-location01" />
                          <span className="txt-city"> Không xác định</span>
                        </div>
                      )}
                      <div className="price-item">
                        <div className="price01 d-flex align-items-center">
                          <span className="text-muted">Giá lẻ:</span>
                          {product.retail_price > 0 ? (
                            <span className="price-clr">
                              &nbsp;<NumberFormat>{product.retail_price}</NumberFormat> đ/{product.unit_name}
                            </span>
                          ) : (
                            <span className="price-clr">Liên hệ</span>
                          )}
                        </div>
                        <div className="price02 mt-1">
                          <span className="d-block text-muted">Lượt xem: {product.view_count * 10}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </FadeIn>
        {productLimit < data?.length && (
          <div className="product-more background-clr-f5 border-0 w-100" onClick={() => onLoadmoreProduct()}>
            <span className="txt hover fw-500">
              <u>Xem thêm</u>
            </span>
            <span className="icon-arrow-solid" style={{ fontSize: '8px', marginLeft: '2px', color: 'grey' }}></span>
          </div>
        )}
      </div>
    </>
  );
};

export default HighlightProducts;
